import React, { useState } from "react";
import YouTube from "react-youtube";
import { getYouTubeID, getYouTubeThumbnail } from "@helper/index";

import * as css from "./video.module.scss";

interface VideoProps {
  link: string;
  quality?: "sd" | "mq" | "hq" | "maxres";
  imgStyle?: React.CSSProperties;
}

const Video: React.FC<VideoProps> = ({ link, quality = "mq", imgStyle }) => {
  const [isPlayed, setIsPlayed] = useState<boolean>(false);

  const handleClick = () => {
    setIsPlayed(true);
  };

  const _onReady = (e: any) => {
    e.target.playVideo();
  };

  if (isPlayed) {
    return (
      <>
        <YouTube
          videoId={getYouTubeID(link)}
          opts={{
            height: "100%",
            width: "100%",
            playerVars: {
              autoplay: 0,
            },
          }}
          className={css.video}
          containerClassName={css.videoContainer}
          onReady={_onReady}
        />
      </>
    );
  } else {
    return (
      <div className={css.videoContainer} onClick={handleClick}>
        <div className={css.playButton}></div>
        <img
          src={getYouTubeThumbnail(link, quality)}
          alt="thumbnail"
          className={css.thumbnail}
          style={imgStyle}
        />
      </div>
    );
  }
};

export default Video;
